import React from 'react'
import './DayForecast.css'

export default class DayForecast extends React.Component{
    constructor(props){
        super();
        let num = props.day;
        this.state = {
            num: num,
            timestamp: null,
            temp: null,
            weather: null,
            icon: null,
            loaded: false,
            error: null
        };
    }

    componentDidMount(){
        this.readJsonData();
        this.timerID = setInterval(
            () => this.checkUpdate(), 300000
        );
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }

    checkUpdate(){
        this.readJsonData();
    }

    getIconFileName(id){
        if ([200,201,202,210,211,212,221,230,231,232].includes(id)){
            return "/images/weather/thunderstorm.png";
        }
        if ([300,301,302,310,311,312,313,314,321,
             520,521,522,531].includes(id)){
            return "/images/weather/shower_rain.png";
        }
        if ([500,501,502,503,501].includes(id)){
            return "/images/weather/rain.png";
        }
        if ([511,
             600,601,602,611,612,613,615,616,620,621].includes(id)){
            return "/images/weather/snow.png";
        }
        if ([701,711,721,731,741,751,761,762,771,781].includes(id)){
            return "/images/weather/mist.png";
        }
        if (id === 800){
            return "/images/weather/clear_sky.png";
        }
        if (id === 801){
            return "/images/weather/few_clouds.png";
        }
        if (id === 802){
            return "/images/weather/scattered_clouds.png";
        }
        if ([803,804].includes(id)){
            return "/images/weather/broken_clouds.png";
        }
    }

    readJsonData(){
        fetch("/weather_data/response.json").then(res => res.json()).then(
            (result) => {
                console.log(result.lat);
                let weatherId = result.daily[this.state.num].weather[0].id;
                let icon = this.getIconFileName(weatherId);
                this.setState({
                    timestamp: result.daily[this.state.num].dt,
                    temp: result.daily[this.state.num].temp,
                    weather: weatherId,
                    icon: icon,
                    loaded: true
                });
            },
            (error) => {
                this.setState({
                    loaded: true,
                    error
                });
            }
        )
    }

    render(){
        if (this.state.error){
            return(
                <div>Error: {this.state.error.message}</div>
            );
        }
        if (this.state.loaded){
            let date = new Date(this.state.timestamp * 1000);
            return(
                <div className='DayForecast'
                    style={{
                        backgroundImage: `url(${this.state.icon})`,
                    }}>
                    <b className='DayForecast-date'>
                        {date.getDate() + '.' + (date.getMonth() + 1) + '.'}
                    </b>
                    <div className='DayForecast-temp'>
                        {Math.round(this.state.temp.day) + ' °C'}
                    </div>
                </div>
            );
        } else {
            return(
                <div>Loading</div>
            );
        }
    }
}