import React from 'react'
import TodayWeather from './TodayWeather/TodayWeather'
import Forecast from './Forecast/Forecast'
import './Weather.css'

export default class Weather extends React.Component{
    render(){
        return(
            <div className='Weather'>
                <TodayWeather/>
                <Forecast/>
            </div>
        )
    }
}