import React from 'react';
import Icon from '../Icons/Icon';
import './Day.css';

export default class Day extends React.Component {
    constructor() {
        super();
        let day = new Date().toLocaleDateString('cs-CZ');
        this.state = {
            currentDay: day
        };
    }

    componentDidMount(){
        this.timerID = setInterval(
            () => this.tick(), 60000
        );
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }

    tick() {
        let day = new Date().toLocaleDateString('cs-CZ');
        this.setState({
            currentDay: day
        });
    }

    render() {
        return (
            <div className="Day-body">
                <div className="Day-icontext">
                    <Icon size="100" icon="calendar"/>
                    <div className="Day-text">{this.state.currentDay}</div>
                </div>
            </div>
        );
    }
}