import React from 'react'
import './Icon.css'

export default class Icon extends React.Component {
    constructor(props) {
        super();
        let size = props.size + "px";
        let icon = this.getIcon(props);
        this.state = {
            size: size,
            icon: icon
        };
    }

    render() {
        return(
            <div className='Icon'>
                <img alt=""
                     src={this.state.icon}
                     width={this.state.size}
                     height={this.state.size}>
                </img>
            </div>
        );
    }

    getIcon(props){
        let icon;
        switch (props.icon){
            case "calendar":
                icon = "/images/date/calendar.png";
                break;
            case "clock":
                icon = "/images/date/clock.png";
                break;
            default:
                break;
        }
        return icon;
    }
}