import React from 'react'
import DayForecast from './DayForecast/DayForecast';
import './Forecast.css'

export default class Forecast extends React.Component{
    render(){
        return(
            <div>
                <div className='Forecast-days'>
                    <DayForecast day='1'/>
                    <DayForecast day='2'/>
                    <DayForecast day='3'/>
                </div>
                <div className='Forecast-days'>
                    <DayForecast day='4'/>
                    <DayForecast day='5'/>
                    <DayForecast day='6'/>
                </div>
            </div>
        );
    }
}