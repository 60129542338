import React from 'react';
import './Clock.css'

export default class Clock extends React.Component {
    constructor() {
        super();
        let today = new Date();
        let hours = today.getHours();
        let checkDay = hours > 5 && hours < 22; 
        let time = today.toLocaleTimeString();
        this.state = {
            currentTime: time,
            isDay: checkDay
        };
    }

    componentDidMount(){
        this.timerID = setInterval(
            () => this.tick(), 1000
        );
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }

    tick() {
        let today = new Date();
        let hours = today.getHours();
        let checkDay = hours > 5 && hours < 22; 
        let time = today.toLocaleTimeString();
        this.setState({
            currentTime: time,
            isDay: checkDay
        });
    }

    render() {
        return (
            <div>
                { this.state.isDay
                ? <div className='Clock-day'>
                    {
                        this.state.currentTime
                    }
                  </div>
                : <div className='Clock-night'>
                    {
                        this.state.currentTime
                    }
                  </div>
                }
            </div>
        );
    }
}